import axios from 'axios';
//export const BASE_URL = 'https://localhost:7182'; //localhost
//export const BASE_URL = 'http://sgetestbe.agsapplications.com'; //test server
export const BASE_URL = 'https://sgeapi.agsapplications.com/'; //production server
//export const BASE_URL = 'http://192.168.1.5:5056'; //test1 server

export const axiosOpen = axios.create({
    baseURL: BASE_URL
});

export const axiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: { ContentType: 'application/json' },
    withCredentials: true
});
