import React from 'react';
//import { axiosAuth } from './apiBase';
import useAxiosAuth from 'hooks/useAxiosAuth';
import { handleResponse, handleError } from './response';
const useDashboardService = () => {
    const axiosAuth = useAxiosAuth();
    const getSalesInquiry = async () => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-sales-inquiry`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getMaintenanceInquiry = async () => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-maintenance-inquiry`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getPartPurchase = async (values) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-partpurchases`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getPartSale = async () => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-partsales`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getProductPurchase = async (values) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-productpurchases`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getProductSale = async () => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-productsales`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getExpenses = async (durationType) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-expenses?durationType=${durationType}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getTodaysInquiryAction = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-todays-inquiry`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getTommorrowsInquiryAction = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-tommorrows-inquiry`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesGraph = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-sales-graph`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getTodaysSales = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-todays-sales`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getSalesInquiry,
        getMaintenanceInquiry,
        getPartPurchase,
        getPartSale,
        getProductPurchase,
        getProductSale,
        getExpenses,
        getTommorrowsInquiryAction,
        getTodaysInquiryAction,
        getSalesGraph,
        getTodaysSales
    };
};

export default useDashboardService;
