import React from 'react';

//MUI imports
import { CircularProgress, Stack, Typography } from '@mui/material';

const Progress = () => {
    return (
        <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginY: 8 }}>
            <CircularProgress />
            <Typography textAlign="center" variant="h4" color="primary">
                Getting Data
            </Typography>
        </Stack>
    );
};

export default Progress;
