import React, { useState } from 'react';

//MUI imports
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

//import Transition from '../Transitions/Transition';

// ***Method to use***
// <DeleteDialog openDialog={state} onCloseFunction={functionName}
// contentText="TITLE GOES HERE"
// handleYes={functionName}
// handleNo={{functionName}}/>

const DeleteDialog = (props) => {
    const { openDialog, onCloseFunction, contentText, handleYes, handleNo } = props;
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <Dialog open={openDialog} onClose={onCloseFunction} fullWidth>
                <DialogTitle>
                    <Typography variant="h4" color="error" sx={{ marginTop: 1 }}>
                        Action Required!
                    </Typography>
                    <Divider sx={{ marginTop: 2 }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5">{contentText}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button onClick={handleYes} variant="contained" startIcon={<CheckIcon />}>
                        Yes
                    </Button>

                    <Button onClick={handleNo} variant="outlined" startIcon={<ClearIcon />}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteDialog;
