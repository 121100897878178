import PropTypes from 'prop-types';
import { Fragment, useContext, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import BajajAreaChartCard from './BajajAreaChartCard';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from 'store/constant';

// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { DashboardContext } from '.';
import NoDataFound from 'ui-component/NoData/NoDataFound';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const TommorrowsInquiryCard = ({ isLoading }) => {
    const theme = useTheme();
    const { tommarowsInquiryData } = useContext(DashboardContext);
    console.log('inq', tommarowsInquiryData);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <MainCard content={false}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container alignContent="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h4">Tommorrow's Inquiry Actions</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        {Array.isArray(tommarowsInquiryData?.salesInquiryActions) &&
                                        tommarowsInquiryData?.salesInquiryActions[0] == undefined ? (
                                            <NoDataFound />
                                        ) : (
                                            Array.isArray(tommarowsInquiryData?.salesInquiryActions) &&
                                            tommarowsInquiryData?.salesInquiryActions.map((ele, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <Grid item>
                                                            <Grid container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" color="inherit">
                                                                        {ele.description}
                                                                    </Typography>
                                                                </Grid>
                                                                {/* <Grid item>
                                                                <Grid container alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1" color="inherit">
                                                                            {ele.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Avatar
                                                                            variant="rounded"
                                                                            sx={{
                                                                                width: 16,
                                                                                height: 16,
                                                                                borderRadius: '5px',
                                                                                backgroundColor: theme.palette.success.light,
                                                                                color: theme.palette.success.dark,
                                                                                ml: 2
                                                                            }}
                                                                        >
                                                                            <KeyboardArrowUpOutlinedIcon fontSize="small" color="inherit" />
                                                                        </Avatar>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <Typography variant="subtitle2" sx={{ color: 'success.dark' }}>
                                                                {ele.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Divider sx={{ my: 1.5 }} /> */}
                                                    </Fragment>
                                                );
                                            })
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                </div>
            )}
        </>
    );
};

TommorrowsInquiryCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TommorrowsInquiryCard;
