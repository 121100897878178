import { Pagination } from '@mui/material';
import React from 'react';

//***Method to use ***/
{
    /* <PaginationComponent count={count} onChange={onChangeFunction} />; */
}

const PaginationComponent = ({ count, onChangeFunction }) => {
    return (
        <>
            <Pagination
                count={count}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                color="primary"
                sx={{ marginY: 2 }}
                onChange={onChangeFunction}
            />
        </>
    );
};

export default PaginationComponent;
