import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './MaintenaceInquiryOngoingCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import useDashboardService from 'services/useDashboardService';
//import ProjectsCard from './ProjectsCard';
import InquiryProjectCard from './InquiryProjectCard';
import CanceledInquiriesProjectCard from './CanceledInquiriesProjectCard';
import TommorrowsInquiryCard from './TommorrowsInquiryCard';
import PieChartExpenses from './PieChartExpenses';
import SalesInquiryCard from './SalesInquiryCard';
import MaintenanceInquiryCard from './MaintenanceInquiryCard';
import ProductPurchaseCard from './ProductPurchaseCard';
import ProductSaleCard from './ProductSaleCard';
import CloseSalesInquiryCountCard from './CloseSalesInquiryCountCard';
import CloseMaintenanceInquiryCountCard from './CloseMaintenanceInquiryCountCard';

export const DashboardContext = React.createContext();
const Dashoboard = () => {
    const [isLoading, setLoading] = useState(true);
    const { userId, displayName, role } = useSelector((state) => state.userReducer);

    //service
    const {
        getSalesInquiry,
        getMaintenanceInquiry,
        getPartPurchase,
        getPartSale,
        getProductPurchase,
        getProductSale,
        getExpenses,
        getTodaysInquiryAction,
        getTommorrowsInquiryAction,
        getSalesGraph,
        getTodaysSales
    } = useDashboardService();

    const [saleInquiryCountData, setSalesInquiryCountData] = useState();
    const [maintenanceInquiryCountData, setMaintenanceInquiryCountData] = useState();
    const [partPurchaseData, setPartPurchaseData] = useState();
    const [partsellData, setPartSellData] = useState();
    const [productPurchaseData, setProductPurchaseData] = useState();
    const [productSaleData, setProductSaleData] = useState();
    const [todaysInquiryData, setTodayInquiryData] = useState();
    const [tommarowsInquiryData, setTomarrowsInquiryData] = useState();
    const [salesGarphData, setSalesGarphData] = useState();
    const [todaysSalesData, setTodaysSalesData] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(false);
        if (userId == 0) {
            navigate('/login');
        }
    }, []);
    const getAllDataFromApi = () => {
        Promise.all([
            getSalesInquiry(),
            getMaintenanceInquiry(),
            getPartPurchase(),
            getPartSale(),
            getProductPurchase(),
            getProductSale(),
            getTodaysInquiryAction(),
            getTommorrowsInquiryAction(),
            getSalesGraph(),
            getTodaysSales()
        ])
            .then(
                ([
                    salesInquiryResponse,
                    maintenanceInquiryResponse,
                    partPurchaseResponse,
                    partSaleResponse,
                    productPurchaseResponse,
                    productSaleResponse,
                    todaysInquiryResponse,
                    tomarrowsInquiryResponse,
                    salesGraphResponse,
                    todaysSalesDataResponse
                ]) => {
                    setSalesInquiryCountData(salesInquiryResponse.data);
                    setMaintenanceInquiryCountData(maintenanceInquiryResponse.data);
                    setPartPurchaseData(partPurchaseResponse.data);
                    setPartSellData(partSaleResponse.data);
                    setProductPurchaseData(productPurchaseResponse?.data),
                        setProductSaleData(productSaleResponse.data),
                        setTodayInquiryData(todaysInquiryResponse.data.salesInquiry);
                    console.log('tomarrow', todaysInquiryResponse.data);
                    setTomarrowsInquiryData(tomarrowsInquiryResponse.data.salesInquiry);
                    setSalesGarphData(salesGraphResponse.data);
                    setTodaysSalesData(todaysSalesDataResponse.data);
                }
            )
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getAllDataFromApi();
    }, []);
    return (
        <DashboardContext.Provider
            value={{
                saleInquiryCountData,
                maintenanceInquiryCountData,
                partPurchaseData,
                partsellData,
                productPurchaseData,
                productSaleData,
                todaysInquiryData,
                tommarowsInquiryData,
                salesGarphData,
                todaysSalesData
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {/* lg={4} md={6} sm={6} */}
                        <Grid item xs={12} sm={6} md={3}>
                            <SalesInquiryCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CloseSalesInquiryCountCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <MaintenanceInquiryCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CloseMaintenanceInquiryCountCard isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={8}>
                            <TotalGrowthBarChart isLoading={isLoading} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TotalIncomeDarkCard isLoading={isLoading} />
                            <TotalIncomeLightCard isLoading={isLoading} />
                            {/* <ProductPurchaseCard isLoading={isLoading} />
                            <ProductSaleCard isLoading={isLoading} /> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <PopularCard isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TommorrowsInquiryCard isLoading={isLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PieChartExpenses isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardContext.Provider>
    );
};

export default Dashoboard;
