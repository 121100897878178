import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

const LoadingButtonComponent = ({ loading, onClickFunction, title, icon: IconComponent, disabled, ...rest }) => {
    const defaultDisabled = false;
    return (
        <>
            <LoadingButton
                {...rest}
                loading={loading}
                onClick={onClickFunction}
                variant="contained"
                type="submit"
                disabled={disabled ? disabled : defaultDisabled}
                startIcon={IconComponent != undefined ? <IconComponent /> : <SaveIcon />}
            >
                {title}
            </LoadingButton>
        </>
    );
};

export default LoadingButtonComponent;
