import React from 'react';
import { handleResponse, handleError } from './response';
import useAxiosAuth from 'hooks/useAxiosAuth';
const useCustomerService = () => {
    const axiosAuth = useAxiosAuth();
    const getStateList = async () => {
        try {
            const response = await axiosAuth.get(`/api/Customer/states`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getCustomer = async () => {
        try {
            const response = await axiosAuth.get(`/api/Customer`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getInternalCustomerList = async () => {
        try {
            const response = await axiosAuth.get(`/api/Customer/internal-customers`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getCustomerById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Customer/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getCustomerListPaginated = async (searchText, customerType, isDelete, gstType, categoryId, skip, take) => {
        try {
            const response = await axiosAuth.get(
                `/api/Customer/filter?searchText=${searchText}&type=${customerType}&isDeleted=${isDelete}&gstType=${gstType}&categoryId=${categoryId}&skip=${skip}&take=${take}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addCustomer = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Customer`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateCustomer = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Customer?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteCustomer = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Customer/soft-delete?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteCustomer = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Customer?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return {
        getCustomer,
        addCustomer,
        updateCustomer,
        deleteCustomer,
        getCustomerListPaginated,
        softDeleteCustomer,
        getStateList,
        getCustomerById,
        getInternalCustomerList
    };
};

export default useCustomerService;
