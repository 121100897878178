// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconCalendarStats
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible,
    IconCalendarStats
};

const serviceEngineerMenu = [
    {
        id: 'main-pages',
        type: 'group',
        children: [
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     type: 'item',
            //     icon: icons.IconDashboard,
            //     url: '/dashboard',
            //     breadcrumbs: false
            // },

            // {
            //     id: 'product',
            //     title: 'Master',
            //     type: 'collapse',
            //     icon: icons.IconReportAnalytics,
            //     breadcrumbs: false,
            //     children: [
            //         {
            //             id: 'customer-category-master',
            //             title: 'Customer Categories',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/customer-category-master',
            //             breadcrumbs: false
            //         },

            //         {
            //             id: 'customer',
            //             title: 'Customers',
            //             type: 'item',
            //             // icon: icons.IconUsers,
            //             url: '/customer',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'vendor-category-master',
            //             title: 'Vendor Categories',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/vendor-category-master',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'vendor',
            //             title: 'Vendors',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/vendor',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'product-category',
            //             title: 'Product Categories',
            //             type: 'item',
            //             //icon: icons.IconFileInvoice,
            //             url: '/product-category',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'product',
            //             title: 'Products',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/product',
            //             breadcrumbs: false
            //         },

            //         {
            //             id: 'part-category-master',
            //             title: 'Part Categories',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/part-category-master',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'part-master',
            //             title: 'Parts',
            //             type: 'item',
            //             // icon: icons.IconReportAnalytics,
            //             url: '/part-master',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'service',
            //             title: 'Services',
            //             type: 'item',
            //             url: '/service',
            //             breadcrumbs: false
            //         }
            //     ]
            // },

            // {
            //     id: 'part-allotment',
            //     icon: icons.IconReportAnalytics,
            //     title: 'Part Allotment',
            //     type: 'item',
            //     url: '/part-allotment',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'quotation',
            //     title: 'Quotation',
            //     type: 'item',
            //     icon: icons.IconNotes,
            //     url: '/quotation',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'sales-invoice',
            //     title: 'Sales Invoice',
            //     type: 'item',
            //     icon: icons.IconReportMoney,
            //     url: '/sales-invoice',
            //     breadcrumbs: false
            // },
            // {
            //     id: 'my-complaints',
            //     icon: icons.IconCalendarStats,
            //     title: 'My Complaints',
            //     type: 'item',
            //     url: '/my-complaints',
            //     breadcrumbs: false
            // },
            {
                id: 'complaints',
                icon: icons.IconNotes,
                title: 'Complaints',
                type: 'item',
                url: '/complaints',
                breadcrumbs: false
            },
            {
                id: 'complaint-history',
                icon: icons.IconCalendarStats,
                title: 'Complaint History',
                type: 'item',
                url: '/complaint-history',
                breadcrumbs: false
            },
            {
                id: 'history',
                title: 'History',
                type: 'collapse',
                icon: icons.IconNotes,
                breadcrumbs: false,
                children: [
                    {
                        id: 'product-history',
                        title: 'Product',
                        type: 'item',
                        //icon: icons.IconReportMoney,
                        url: '/product-history',
                        breadcrumbs: false
                    },
                    {
                        id: 'part-history',
                        title: 'Part',
                        type: 'item',
                        //icon: icons.IconReportMoney,
                        url: '/part-history',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
];

export default serviceEngineerMenu;
