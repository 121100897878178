import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
import useAxiosAuth from 'hooks/useAxiosAuth';
const useLocationService = () => {
    const axiosAuth = useAxiosAuth();
    const getLocation = async () => {
        try {
            const response = await axiosAuth.get(`/api/Location`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getLocation };
};

export default useLocationService;
