import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import ownerMenu from 'menu-items/ownerMenu';
import adminMenu from 'menu-items/adminMenu';
import salesExecutiveMenu from 'menu-items/salesExecutiveMenu';
import officeStaffMenu from 'menu-items/officeStaffMenu';
import serviceEngineerMenu from 'menu-items/serviceEngineerMenu';
import accountsMenu from 'menu-items/accountsMenu';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { role, locationId } = useSelector((state) => state.userReducer);
    // const role = 1;
    let menuItemsTobAdded = [];
    if (role == 1) {
        menuItemsTobAdded = ownerMenu;
    } else if (role == 2) {
        if (locationId == 2) {
            menuItemsTobAdded = adminMenu;
        } else if (locationId != 2) {
            const eleIndex =
                Array.isArray(adminMenu) &&
                Array.isArray(adminMenu[0]?.children) &&
                adminMenu[0]?.children.findIndex((ele) => ele.id == 'transactions');
            const ele =
                Array.isArray(adminMenu) &&
                Array.isArray(adminMenu[0]?.children) &&
                adminMenu[0]?.children.find((ele) => ele.id == 'transactions');

            if (ele) {
                const indexOfItem = Array.isArray(ele.children) && ele.children.findIndex((item) => item.id == 'transfer-records');
                console.log('indexOfItem', indexOfItem, ele.children);
                if (indexOfItem != -1) {
                    ele.children.splice(indexOfItem, 1);
                    console.log('newChildArray', ele.children);
                }

                adminMenu[0].children[eleIndex] = ele;
                console.log('ele', ele);
            }

            menuItemsTobAdded = adminMenu;
        }
    } else if (role == 3) {
        menuItemsTobAdded = salesExecutiveMenu;
    } else if (role == 4) {
        menuItemsTobAdded = officeStaffMenu;
    } else if (role == 5) {
        menuItemsTobAdded = serviceEngineerMenu;
    } else if (role == 7) {
        menuItemsTobAdded = accountsMenu;
    }
    // menuItemsTobAdded = adminMenuItems;

    const navItems = menuItemsTobAdded
        // .filter((item) => item?.id == 'transfer-records')
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
